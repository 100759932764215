import Heading from 'Components/Heading'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SettingsActions from 'Redux/SettingsRedux'
import store from 'store'
import Toast from 'Components/Toast'
import ImageLinks from 'Themes/ImageLinks'
import { ClipboardCopyIcon, ArrowRightIcon } from '@heroicons/react/outline'
import AppleCalendarModal from 'Components/Modals/AppleCalendarModal'
import StripeModal from 'Components/Modals/StripeModal'
import MobileAppModal from 'Components/Modals/MobileAppModal'
import GoogleCalendarModal from 'Components/Modals/GoogleCalendarModal'

const integrationsData = [
  {
    label: 'Google Calendar',
    name: 'Google',
    status: 'notConnected',
    imageUrl: 'https://blog.hubspot.com/hubfs/image8-2.jpg',
    key: 'google',
    subText: 'Enable 2 way sync by connecting your Google Calendar',
    errorText:
      'There was an error with the Calendar. Please disconnect and connect your calendar',
  },
  {
    label: 'Outlook Calendar',
    name: 'Microsoft',
    status: 'notConnected',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/512px-Microsoft_logo.svg.png',
    key: 'microsoft',
    subText: 'Enable 2 way sync by connecting your Outlook Calendar',
  },
  {
    label: 'Apple Calendar',
    name: 'Apple',
    status: 'notConnected',
    imageUrl:
      'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg',
    key: 'apple',
    subText: 'Enable 2 way sync by connecting your Apple Calendar',
    errorText:
      'There was an error with the Calendar. Please disconnect and connect your calendar',
  },
  {
    label: 'Zoom',
    name: 'Zoom',
    status: 'notConnected',
    imageUrl:
      'https://www.nicepng.com/png/detail/1008-10087079_zoom-icon-zoom-video-conferencing-logo.png',
    key: 'zoom',
    subText: 'Generate automatic zoom meeting links for your appointments',
  },
  {
    label: 'Google Meet',
    name: 'Google',
    status: 'notConnected',
    imageUrl:
      'https://play-lh.googleusercontent.com/6wvcwcC_v4bBwA7K1eDoKw_PDtvdpHwhKAisZftnoY_Jn94Rjh7u7YSV0IEn9uZM2A=w600-h300-pc0xffffff-pd',
    key: 'google',
    subText: 'Enable 2 way sync by connecting your Google Meet',
    errorText:
      'There was an error with the Calendar. Please disconnect and connect your calendar',
  },
  {
    label: 'Microsoft Teams',
    name: 'Microsoft',
    status: 'notConnected',
    imageUrl:
      'https://images.ctfassets.net/lzny33ho1g45/set-up-microsoft-teams-free-p-img/479b275f9fc0b83fe58b7c967e370114/file.png?w=1520&fm=avif&q=30&fit=thumb&h=760',
    key: 'microsoft',
    subText: 'Enable 2 way sync by connecting your Microsoft Teams',
    errorText:
      'There was an error with the Calendar. Please disconnect and connect your calendar',
  },
  {
    label: 'Stripe',
    name: 'Stripe',
    status: 'notConnected',
    imageUrl:
      'https://images.ctfassets.net/fzn2n1nzq965/HTTOloNPhisV9P4hlMPNA/cacf1bb88b9fc492dfad34378d844280/Stripe_icon_-_square.svg?q=80&w=1082',
    key: 'stripe',
    subText: 'Connect Stripe for your payment gateway',
  },
]

const integrationDetail = [
  {
    label: 'Thinkific',
    name: 'Thinkific',
    imageUrl:
      'https://forcreators.com/wp-content/uploads/2021/03/Thinkific-logo.png',
    key: 'thinkific',
    subText:
      "Elevate Your Online Courses with Appointo's Thinkific Integration.",
    link: 'https://apps.thinkific.com/apps/appointo',
  },
  {
    label: 'Shopify',
    name: 'Shopify',
    imageUrl:
      'https://cdn.shopify.com/shopifycloud/brochure/assets/brand-assets/shopify-logo-primary-logo-456baa801ee66a0a435671082365958316831c9960c480451dd0330bcdae304f.svg',
    key: 'shopify',
    subText:
      'Seamlessly Connect Your Shopify Store with Appointo for Enhanced Scheduling and Sales Management',
    link: 'https://apps.shopify.com/appointo',
  },
  {
    label: 'Hubspot',
    name: 'Hubspot',
    imageUrl:
      'https://community.hubspot.com/html/@A9AECFE839FED450E23DDFE84C4F98D9/assets/sticky-logo.png',
    key: 'hubspot',
    subText: 'Send Appointment to Hubspot and Manage from Hubspot Dashboard',
    link: 'https://ecosystem.hubspot.com/marketplace/apps/sales/scheduling/appointo',
  },
]

const Integrations = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showToast, setShowToast] = useState(false)
  const [content, setContent] = useState('')
  const [integrations, setIntegrations] = useState(integrationsData)
  const [currentCalendar, setCurrentCalendar] = useState({})
  const userData = store.get('userData')
  const [apiToken, setApiToken] = useState('')
  const updateFetching = useSelector((state) => state.setting.updateFetching)
  const [apiLoading, setApiLoading] = useState(false)
  const [zapierLoading, setZapierLoading] = useState(false)
  const [zapierToken, setZapierToken] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [appleFormData, setAppleFormData] = useState({
    email: '',
    password: '',
  })
  const [showStripeModal, setShowStripeModal] = useState(false)
  const [showMobileAppModal, setShowMobileAppModal] = useState(false)
  const [mobileAppKey, setMobileAppKey] = useState('')
  const [mobileToken, setMobileToken] = useState(null)
  const [showGoogleCalendarModal, setShowGoogleCalendarModal] = useState(false)

  const setAppleData = (field) => (value) => {
    setAppleFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  const onConnect = (name) => () => {
    switch (name) {
      case 'Google':
        signInGoogle()
        break
      case 'Microsoft':
        signInMicrosoft()
        break
      case 'Zoom':
        singInZoom()
        break
      case 'Apple':
        openAppleCalModal()
        break
      case 'Stripe':
        openStripeModal()
    }
  }

  const openStripeModal = () => {
    setShowStripeModal(true)
  }

  const openAppleCalModal = () => {
    setShowModal(true)
  }

  const checkAppleCal = () => {
    let calConnection = userData.cal_connection
    if (calConnection.calendar_type === 'apple') {
      dispatch(
        SettingsActions.disconnectAppleCalendarRequest((resp) => {
          setShowModal(false)
          setIntegrationData()
          setShowToast(true)
          setContent('Calendar disconnected successfully')
        })
      )
    } else {
      dispatch(
        SettingsActions.connectAppleCalendarRequest(appleFormData, (resp) => {
          setIntegrationData()
          setShowToast(true)
          setContent('Calendar connected successfully')
          setShowModal(false)
          setAppleFormData({
            email: '',
            password: '',
          })
        })
      )
    }
  }

  useEffect(() => {
    setIntegrationData()
  }, [])

  const setIntegrationData = () => {
    const userData = store.get('userData')
    let zoom_connection = userData.zoom_detail
    let calConnection = userData.cal_connection
    setMobileToken(userData.shop.mobile_token)

    let tempInt = integrations
    if (calConnection.calendar_type) {
      switch (calConnection.calendar_type) {
        case 'google':
          tempInt = tempInt.map((t) => {
            if (t.key === 'google') {
              return {
                ...t,
                status:
                  calConnection.status === 'error' ? 'error' : 'connected',
                metadata: calConnection,
              }
            }
            return t
          })

          break
        case 'microsoft':
          tempInt = tempInt.map((t) => {
            if (t.key === 'microsoft') {
              return {
                ...t,
                status:
                  calConnection.status === 'error' ? 'error' : 'connected',
                metadata: calConnection,
              }
            }
            return t
          })

          break
        case 'apple':
          tempInt = tempInt.map((t) => {
            if (t.key === 'apple') {
              return {
                ...t,
                status:
                  calConnection.status === 'error' ? 'error' : 'connected',
                metadata: calConnection,
              }
            }

            return t
          })

          break
      }
    } else {
      tempInt = tempInt.map((t) => {
        return {
          ...t,
          status: 'notConnected',
          metadata: null,
        }
      })
    }

    if (!!zoom_connection) {
      tempInt = tempInt.map((t) => {
        if (t.key === 'zoom') {
          return {
            ...t,
            status: 'connected',
            metadata: {
              name: `${zoom_connection.first_name} ${zoom_connection.last_name}`,
              email: `${zoom_connection.email}`,
            },
          }
        }
        return t
      })
    }

    setIntegrations((integrations) => [...tempInt])
  }

  const onDisconnect = (name) => () => {
    switch (name) {
      case 'Google':
        disconnectCalendar()
        break
      case 'Microsoft':
        disconnectCalendar()
        break
      case 'Zoom':
        disconnectZoom()
        break
      case 'Apple':
        openAppleCalModal()
        break
    }
  }

  const signInGoogle = () => {
    setShowGoogleCalendarModal(true)
  }

  const connectGoogleCalendar = () => {
    window.auth2.grantOfflineAccess({ prompt: 'consent' }).then((response) => {
      if (response.code) {
        dispatch(
          SettingsActions.connectGoogleCalendar(response.code, (status) => {
            if (status === 'success') {
              setIntegrationData()
              setShowToast(true)
              setContent('Calendar connected successfully')
            } else {
              setShowToast(true)
              setContent(
                'There was an error connecting calendar. Please contact support for help'
              )
            }
          })
        )
      }
    })
  }

  const disconnectCalendar = () => {
    dispatch(
      SettingsActions.disconnectGoogleCalendar(() => {
        // window.auth2.getAuthInstance().disconnect()
        setShowToast(true)
        setContent('Calendar disconnected successfully')
        setIntegrationData()
      })
    )
  }

  const disconnectZoom = () => {
    dispatch(
      SettingsActions.disconnectZoom(() => {
        setShowToast(true)
        setContent('Zoom disconnected successfully')
        setIntegrationData()
      })
    )
  }

  const signInMicrosoft = () => {
    window.open(
      `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}
      &response_type=code
      &redirect_uri=${process.env.REACT_APP_BASE_URL}/scripttag/ms_outlook_redirected
      &response_mode=query
      &scope=calendars.read calendars.readwrite user.read offline_access
      &state=member:${userData.id}`,
      '_blank'
    )
  }

  const singInZoom = () => {
    window.open(
      `https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_BASE_URL}/scripttag/zoom_redirected&state=member:${userData.id}`,
      '_blank'
    )
  }

  const disconnectMicrosoft = () => {}

  const generateZapierToken = () => {
    setZapierLoading(true)
    dispatch(
      SettingsActions.generateZapierApiToken((data) => {
        setZapierToken(data.api_zapier_token)
        store.set('userData', {
          ...userData,
          shop: {
            ...userData.shop,
            api_zapier_token: data.api_zapier_token,
          },
        })
        setZapierLoading(false)
      })
    )
  }

  const generateApiToken = () => {
    setApiLoading(true)
    dispatch(
      SettingsActions.generateApiToken((data) => {
        setApiToken(data.api_token)
        store.set('userData', {
          ...userData,
          shop: {
            ...userData.shop,
            api_token: data.api_token,
          },
        })
        setApiLoading(false)
      })
    )
  }

  return (
    <div>
      <Toast show={showToast} setShow={setShowToast} title={content} />
      <Heading title="Integrations" />
      <ul className="grid gap-6 sm:grid-cols-2 xl:grid-cols-3 my-6">
        <>
          {integrations.map((i) => (
            <>
              {!(i.name === 'Stripe' && userData.shop.flow === 'shopify') && (
                <li
                  key={i.label}
                  className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
                >
                  <div>
                    <div className="w-full flex items-center justify-between p-6 space-x-6">
                      <div className="flex-1">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-gray-900 text-md font-medium truncate">
                            {i.label}
                          </h3>
                          <span
                            className={`flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium  rounded-full ${
                              i.status === 'notConnected' ||
                              i.status === 'error'
                                ? 'bg-red-100 text-red-800'
                                : 'bg-green-100 text-green-800'
                            }`}
                          >
                            {i.status === 'notConnected'
                              ? 'Not Connected'
                              : i.status === 'error'
                              ? 'Error'
                              : 'Connected'}
                          </span>
                        </div>
                        {i.metadata ? (
                          <h3
                            className="text-gray-900 text-sm font-medium truncate mt-4"
                            style={{ height: 40 }}
                          >
                            Name:{' '}
                            {i.metadata.name
                              ? i.metadata.name
                              : i.metadata.calendar_name}
                            <br />
                            Email: {i.metadata.email}
                          </h3>
                        ) : (
                          <h3
                            className="text-gray-900 text-sm font-medium truncate mt-4"
                            style={{ height: 40 }}
                          ></h3>
                        )}
                      </div>
                      <img
                        className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                        src={i.imageUrl}
                        alt=""
                      />
                    </div>
                    <p
                      className={`text-sm p-6 pt-0 w-70 ${
                        i.status === 'error'
                          ? 'text-red-500 text-md font-bold'
                          : ''
                      }`}
                      style={{
                        minHeight: '86px',
                      }}
                    >
                      {i.status === 'error' ? i.errorText : i.subText}
                    </p>
                  </div>

                  <div>
                    <div
                      className="-mt-px flex divide-x divide-gray-200 cursor-pointer"
                      onClick={
                        i.status === 'notConnected'
                          ? onConnect(i.name)
                          : onDisconnect(i.name)
                      }
                    >
                      <div className="-ml-px w-0 flex-1 flex">
                        <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                          <span className="ml-3">
                            {i.status === 'notConnected'
                              ? 'Connect'
                              : 'Disconnect'}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </>
          ))}

          {userData.shop.flow === 'shopify' && (
            <>
              <li
                key="android_app"
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
              >
                <div>
                  <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 text-md font-medium truncate">
                          Android App
                        </h3>
                        <span
                          className={`flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium  rounded-full ${
                            !mobileToken
                              ? 'bg-red-100 text-red-800'
                              : 'bg-green-100 text-green-800'
                          }`}
                        >
                          {!mobileToken ? 'Disabled' : 'Enabled'}
                        </span>
                      </div>
                      <h3
                        className="text-gray-900 text-sm font-medium truncate mt-4"
                        style={{ height: 40 }}
                      ></h3>
                    </div>
                    <img
                      className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                      src="https://www.gstatic.com/android/market_images/web/play_prism_160px_v2.png"
                      alt=""
                    />
                  </div>
                  <div
                    className="text-sm p-6 pt-0 w-70"
                    style={{
                      minHeight: '86px',
                    }}
                  >
                    <p>Use the app to manage your availabilities</p>
                    {mobileToken && (
                      <p className="pt-1 flex gap-2">
                        Token :{' '}
                        {mobileToken && (
                          <p className="font-bold flex gap-1">
                            {mobileToken}
                            <span
                              onClick={() => {
                                navigator.clipboard.writeText(mobileToken)
                                setShowToast(true)
                                setContent(
                                  'Mobile App Token Copied Successfully!'
                                )
                              }}
                              className="flex gap-1 items-center text-xs hover:underline cursor-pointer"
                            >
                              <ClipboardCopyIcon
                                className="h-5 w-5"
                                strokeWidth={1.2}
                              />{' '}
                              Copy
                            </span>
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <div
                    className="-mt-px flex divide-x divide-gray-200 cursor-pointer"
                    onClick={() => {
                      if (mobileToken) {
                        setMobileAppKey('android_app')
                        setShowMobileAppModal(true)
                      } else {
                        dispatch(
                          SettingsActions.generateAppToken(
                            {
                              shop_id: userData?.shop?.id,
                            },
                            (data) => {
                              setMobileToken(data.mobile_token)
                            }
                          )
                        )
                      }
                    }}
                  >
                    <div className="-ml-px w-0 flex-1 flex">
                      <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                        <span className="ml-3">
                          {mobileToken ? 'Enabled' : 'Generate Token'}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
              <li
                key="android_app"
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
              >
                <div>
                  <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1">
                      <div className="flex items-center space-x-3">
                        <h3 className="text-gray-900 text-md font-medium truncate">
                          IOS App
                        </h3>
                        <span
                          className={`flex-shrink-0 inline-block px-2 py-0.5 text-xs font-medium  rounded-full ${
                            !mobileToken
                              ? 'bg-red-100 text-red-800'
                              : 'bg-green-100 text-green-800'
                          }`}
                        >
                          {!mobileToken ? 'Disabled' : 'Enabled'}
                        </span>
                      </div>
                      <h3
                        className="text-gray-900 text-sm font-medium truncate mt-4"
                        style={{ height: 40 }}
                      ></h3>
                    </div>
                    <img
                      className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                      src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large_2x.png"
                      alt=""
                    />
                  </div>
                  <div
                    className="text-sm p-6 pt-0 w-70"
                    style={{
                      minHeight: '86px',
                    }}
                  >
                    <p>Use the app to manage your availabilities</p>
                    {mobileToken && (
                      <p className="pt-1 flex gap-2">
                        Token :{' '}
                        {mobileToken && (
                          <p className="font-bold flex gap-1">
                            {mobileToken}
                            <span
                              onClick={() => {
                                navigator.clipboard.writeText(mobileToken)
                                setShowToast(true)
                                setContent(
                                  'Mobile App Token Copied Successfully!'
                                )
                              }}
                              className="flex gap-1 items-center text-xs hover:underline cursor-pointer"
                            >
                              <ClipboardCopyIcon
                                className="h-5 w-5"
                                strokeWidth={1}
                              />{' '}
                              Copy
                            </span>
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div
                    className="-mt-px flex divide-x divide-gray-200 cursor-pointer"
                    onClick={() => {
                      if (mobileToken) {
                        setMobileAppKey('ios_app')
                        setShowMobileAppModal(true)
                      } else {
                        dispatch(
                          SettingsActions.generateAppToken(
                            {
                              shop_id: userData?.shop?.id,
                            },
                            (data) => {
                              setMobileToken(data.mobile_token)
                            }
                          )
                        )
                      }
                    }}
                  >
                    <div className="-ml-px w-0 flex-1 flex">
                      <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                        <span className="ml-3">
                          {mobileToken ? 'Enabled' : 'Generate Token'}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            </>
          )}
        </>

        {userData.shop.flow !== 'shopify' && (
          <>
            <li
              key="zapier"
              className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div>
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <div
                    className="flex-1 truncate"
                    style={{
                      minHeight: 90,
                    }}
                  >
                    <div className="flex items-center space-x-3">
                      <a
                        title="See docs"
                        href="https://zapier.com/apps/appointo/integrations"
                        target="_blank"
                        className="text-gray-900  flex items-center gap-1 text-md font-medium truncate hover:underline"
                      >
                        Zapier
                        <ArrowRightIcon className="h-4 w-4" />
                      </a>
                    </div>

                    <div
                      className="text-gray-600 text-sm font-medium truncate mt-4"
                      style={{ height: 30, overflowX: 'scroll' }}
                    >
                      {zapierToken && (
                        <>
                          <p>
                            <strong>{zapierToken}</strong>
                          </p>
                        </>
                      )}
                    </div>
                    {zapierToken && (
                      <p
                        onClick={() => {
                          navigator.clipboard.writeText(zapierToken)
                          setShowToast(true)
                          setContent('Zapier Token Copied Successfully!')
                        }}
                        className="flex gap-1 items-center text-xs hover:underline cursor-pointer"
                      >
                        <ClipboardCopyIcon
                          className="h-5 w-5"
                          strokeWidth={1.2}
                        />{' '}
                        Copy
                      </p>
                    )}
                  </div>
                  <img
                    className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                    src={ImageLinks.Integration.zapierLogo}
                    alt=""
                  />
                </div>
                <p
                  className="text-sm p-6 pt-0 w-70"
                  style={{
                    minHeight: '77px',
                  }}
                >
                  Connect to 2000+ apps outside Appointo by using Zapier
                </p>
              </div>

              <div>
                <div
                  className="-mt-px flex divide-x divide-gray-200 cursor-pointer"
                  onClick={generateZapierToken}
                >
                  <div className="-ml-px w-0 flex-1 flex">
                    <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                      <span className="ml-3">
                        {zapierLoading
                          ? 'Generating Token...'
                          : 'Generate Token'}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li
              key="api"
              className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <div>
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <div
                    className="flex-1 truncate"
                    style={{
                      minHeight: 90,
                    }}
                  >
                    <div className="flex items-center space-x-3">
                      <a
                        title="See docs"
                        href="https://api-docs.appointo.me/"
                        target="_blank"
                        className="text-gray-900  flex items-center gap-1 text-md font-medium truncate hover:underline"
                      >
                        API Token
                        <ArrowRightIcon className="h-4 w-4" />
                      </a>
                    </div>
                    <div
                      className="text-gray-600 text-sm font-medium truncate mt-4"
                      style={{ height: 30, overflowX: 'scroll' }}
                    >
                      {apiToken && (
                        <>
                          <p>
                            <strong>{apiToken}</strong>
                          </p>
                        </>
                      )}
                    </div>
                    {apiToken && (
                      <p
                        onClick={() => {
                          navigator.clipboard.writeText(apiToken)
                          setShowToast(true)
                          setContent('API Token Copied Successfully!')
                        }}
                        className="flex gap-1 items-center text-xs hover:underline cursor-pointer"
                      >
                        <ClipboardCopyIcon
                          className="h-5 w-5"
                          strokeWidth={1.2}
                        />{' '}
                        Copy
                      </p>
                    )}
                  </div>
                  <img
                    className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                    src={ImageLinks.Integration.apiLogo}
                    alt=""
                  />
                </div>
                <p
                  className="text-sm p-6 pt-0 w-70"
                  style={{
                    minHeight: '86px',
                  }}
                >
                  Use Appointo Api to build your own integration
                </p>
              </div>

              <div>
                <div
                  className="-mt-px flex divide-x divide-gray-200 cursor-pointer"
                  onClick={generateApiToken}
                >
                  <div className="-ml-px w-0 flex-1 flex">
                    <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                      <span className="ml-3">
                        {apiLoading ? 'Generating Token...' : 'Generate Token'}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            {integrationDetail.map((i) => (
              <li
                key={i.label}
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
              >
                <div>
                  <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div
                      className="flex-1 truncate"
                      style={{
                        minHeight: 90,
                      }}
                    >
                      <div className="flex items-center space-x-3">
                        <a
                          title="See docs"
                          href={i.link}
                          target="_blank"
                          className="text-gray-900  flex items-center gap-1 text-md font-medium truncate hover:underline"
                        >
                          {i.label}
                          <ArrowRightIcon className="h-4 w-4" />
                        </a>
                      </div>
                      <div
                        className="text-gray-600 text-sm font-medium truncate mt-4"
                        style={{ height: 30, overflowX: 'scroll' }}
                      ></div>
                    </div>
                    <img
                      className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                      src={i.imageUrl}
                      alt=""
                    />
                  </div>
                  <p
                    className="text-sm p-6 pt-0 w-70"
                    style={{
                      minHeight: '86px',
                    }}
                  >
                    {i.subText}
                  </p>
                </div>

                <div>
                  <div
                    className="-mt-px flex divide-x divide-gray-200 cursor-pointer"
                    onClick={() => window.open(i.link)}
                  >
                    <div className="-ml-px w-0 flex-1 flex">
                      <a className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                        <span className="ml-3">See Details</span>
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </>
        )}
      </ul>
      {showModal && (
        <AppleCalendarModal
          setShowModal={setShowModal}
          showModal={showModal}
          action={checkAppleCal}
          appleFormData={appleFormData}
          setAppleData={setAppleData}
        />
      )}
      {showStripeModal && (
        <StripeModal
          showModal={showStripeModal}
          setShowModal={setShowStripeModal}
        />
      )}
      {showMobileAppModal && (
        <MobileAppModal
          showModal={showMobileAppModal}
          setShowModal={setShowMobileAppModal}
          mobileAppKey={mobileAppKey}
        />
      )}
      {showGoogleCalendarModal && (
        <GoogleCalendarModal
          showModal={showGoogleCalendarModal}
          setShowModal={setShowGoogleCalendarModal}
          action={connectGoogleCalendar}
          disconnectCalendar={disconnectCalendar}
        />
      )}
    </div>
  )
}

export default Integrations
