import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  updateRequest,
  updateSuccess,
  updateFailure,
  setObject,
  listRequest,
  listSuccess,
  listFailure,
  createRequest,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  settingsUpdateRequest: ['params', 'callback'],
  settingsUpdateSuccess: ['data'],
  settingsUpdateFailure: ['error'],

  settingsPlanUpgradeRequest: ['params', 'callback'],
  settingsPlanCancelRequest: ['params', 'callback'],
  connectGoogleCalendar: ['code', 'callback'],
  disconnectGoogleCalendar: ['callback'],
  disconnectZoom: ['callback'],
  textSettingUpdateRequest: ['params', 'callback'],
  generateZapierApiToken: ['callback'],
  generateApiToken: ['callback'],
  disconnectStripeAccount: ['callback'],

  setSettings: ['data'],

  connectAppleCalendarRequest: ['params', 'callback'],
  connectAppleCalendarSuccess: ['data'],
  connectAppleCalendarFailure: ['error'],
  disconnectAppleCalendarRequest: ['callback'],
  disconnectAppleCalendarSuccess: ['data'],
  disconnectAppleCalendarFailure: ['error'],

  settingsTeamMemberUpdateRequest: ['params', 'callback'],
  settingsTeamMemberUpdateSuccess: ['data'],
  settingsTeamMemberUpdateFailure: ['error'],

  settingsPlanCancelRequest: ['callback'],
  settingsPlanCancelSuccess: ['data'],
  settingsPlanCancelFailure: ['error'],

  generateAppToken: ['params', 'callback'],
})

export const SettingsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  updateData: {},
  updateError: null,
  updateFetching: false,

  appleCalUpdateData: {},
  appleCalUpdateFetching: false,
  appleCalUpdateError: null,

  data: {},

  planError: null,
  planFetching: false,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const appleCalUpdateRequest = (state, { appleCalUpdateParams }) => {
  return state.merge({
    appleCalUpdateParams,
    appleCalUpdateFetching: true,
    appleCalUpdateError: null,
  })
}

export const appleCalUpdateSuccess = (state, { appleCalUpdateData }) => {
  return state.merge({ appleCalUpdateData, appleCalUpdateFetching: false })
}

export const appleCalUpdateFailure = (state, { appleCalUpdateError }) => {
  return state.merge({ appleCalUpdateError, appleCalUpdateFetching: false })
}

export const planRequest = (state) => {
  return state.merge({ planFetching: true, planError: null })
}

export const planSuccess = (state, { data }) => {
  return state.merge({ data, planFetching: false })
}

export const planFailure = (state, { planError }) => {
  return state.merge({ planError, planFetching: false })
}

/* ------------- Selectors ------------- */

export const SettingsSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SETTINGS_UPDATE_REQUEST]: updateRequest,
  [Types.SETTINGS_UPDATE_SUCCESS]: updateSuccess,
  [Types.SETTINGS_UPDATE_FAILURE]: updateFailure,

  [Types.SET_SETTINGS]: setObject,

  [Types.CONNECT_APPLE_CALENDAR_REQUEST]: appleCalUpdateRequest,
  [Types.CONNECT_APPLE_CALENDAR_SUCCESS]: appleCalUpdateSuccess,
  [Types.CONNECT_APPLE_CALENDAR_FAILURE]: appleCalUpdateFailure,

  [Types.DISCONNECT_APPLE_CALENDAR_REQUEST]: appleCalUpdateRequest,
  [Types.DISCONNECT_APPLE_CALENDAR_SUCCESS]: appleCalUpdateSuccess,
  [Types.DISCONNECT_APPLE_CALENDAR_FAILURE]: appleCalUpdateFailure,

  [Types.SETTINGS_TEAM_MEMBER_UPDATE_REQUEST]: updateRequest,
  [Types.SETTINGS_TEAM_MEMBER_UPDATE_SUCCESS]: updateSuccess,
  [Types.SETTINGS_TEAM_MEMBER_UPDATE_FAILURE]: updateFailure,

  [Types.SETTINGS_PLAN_CANCEL_REQUEST]: planRequest,
  [Types.SETTINGS_PLAN_CANCEL_SUCCESS]: planSuccess,
  [Types.SETTINGS_PLAN_CANCEL_FAILURE]: planFailure,
})
