import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'Components/Card'
import BookingActions from 'Redux/BookingRedux'
import Input from 'Components/Input'
import Modal from 'Components/Modal'
import Button from 'Components/Button'
import { create } from 'apisauce'

function CustomerCreateModal(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const createCustomer = () => {
    dispatch(
      BookingActions.createCustomer(
        { email, first_name: firstName, last_name: lastName, phone: phone },
        (data, error) => {
          if (!!error) {
            window.alert(error)
          } else {
            props.postSave(data.customer)
            props.setShowModal(false)
          }
        }
      )
    )
  }
  console.log(props, 'asdfghjkl')
  return (
    <Modal
      onClose={() => {
        props.setShowModal(false)
      }}
      showModal={props.showModal}
      title="Create Customer"
      primaryButton={{
        title: 'Create',
        onClick: createCustomer,
        loading: loading,
        disabled: !email && !phone,
      }}
    >
      <div style={{ padding: 20 }}>
        <Input
          label="First Name"
          onChange={(value) => setFirstName(value)}
          value={firstName}
          placeholder="Please enter first name"
        />
        <div style={{ marginTop: 20 }}>
          <Input
            label="Last Name"
            onChange={(value) => setLastName(value)}
            value={lastName}
            placeholder="Please enter last name"
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Input
            label="Email"
            onChange={(value) => setEmail(value)}
            value={email}
            type="email"
            placeholder="Please enter the email"
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <Input
            label="Phone"
            onChange={(value) => setPhone(value)}
            value={phone}
            placeholder="Please enter the phone number"
          />
        </div>
      </div>
    </Modal>
  )
}

export default CustomerCreateModal
