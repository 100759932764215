import Modal from 'Components/Modal'
import React, { useCallback, useState } from 'react'
import Input from 'Components/Input'
import BookingActions from 'Redux/BookingRedux'
import { useDispatch, useSelector } from 'react-redux'
import { TrackEvent } from 'Themes/constants'

function RefundAmountModal(props) {
  let stripeData =
    !!props.customer && !!props.customer.stripe_payload
      ? props.customer.stripe_payload
      : null
  const dispatch = useDispatch()
  const [amount, setAmount] = useState('')
  const [loading, setLoading] = useState(false)

  if (!stripeData) {
    return null
  }
  const stripeAmount = stripeData?.data?.object?.amount_total ?? 0
  let refundedAmount = 0
  if (!!stripeData && !!stripeData.refund_details) {
    stripeData.refund_details.map((r) => {
      refundedAmount += r.amount
    })
  }

  let currentRemaining = (stripeAmount - refundedAmount) / 100

  const refundAmount = () => {
    setLoading(true)
    let params = {
      customer_id: props.customer.id,
      amount: amount,
    }

    TrackEvent('Refund Booking', params)
    dispatch(
      BookingActions.refundAmount(params, () => {
        setLoading(false)
        props.handleClose()
      })
    )
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: 'Refund',
          onClick: refundAmount,
          loading: loading,
          type: 'primary',
        }}
      >
        <div className=" grid grid-cols-9 gap-y-6 gap-x-4 mb-4">
          <Input
            id={`amount`}
            label={`Refund Amount(Remaining Amount: ${currentRemaining})`}
            onChange={(value) => {
              setAmount(value)
            }}
            value={amount}
            placeholder="Enter Amount"
            containerClass="col-span-4"
          />
        </div>
      </Modal>
    </div>
  )
}

export default RefundAmountModal
