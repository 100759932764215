import Modal from 'Components/Modal'
import { Crisp } from 'crisp-sdk-web'
import React from 'react'
import store from 'store'

const GoogleCalendarModal = (props) => {
  const userData = store.get('userData')

  const handleClick = () => {
    props.setShowModal(false)
    props.action()
  }

  return (
    <>
      <Modal
        showModal={props.showModal}
        onClose={() => props.setShowModal(false)}
        title="[IMPORTANT] Please read carefully!"
        primaryButton={{
          title: 'Yes, I understand',
          onClick: handleClick,
        }}
      >
        <>
          <p className="text-sm">
            Please make sure you check the box while giving permissions for your
            calendar. This is required for us to do a proper sync back and
            forth.
          </p>
          <p className="text-sm font-bold">
            If you do not check the box, the permissions won't be given and we
            won't be able to sync your calendar correctly
          </p>

          <div className="flex justify-center items-center my-2">
            <img
              className="w-1/2 h-full"
              src="https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com/CleanShot+2021-07-28+at+16.28.20%402x.png?fbclid=IwAR0qnAiXFcunepSw7k_epiD6ZrQyRwr148wFmejSKEpKqUzNfTMqZX3iLxo"
            />
          </div>
          <br />
          <p className="text-sm">
            Still Confused?{' '}
            <a
              className="text-blue-500 underline cursor-pointer"
              onClick={() => {
                Crisp.chat.open()
              }}
            >
              Chat with us
            </a>
          </p>
        </>
      </Modal>
    </>
  )
}

export default GoogleCalendarModal
